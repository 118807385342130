<template>
  <el-button :disabled="disable" :loading="load" class="send-btn-c" type="danger" @click="send">{{ text }}</el-button>
</template>

<script>
export default {
  name: "SendCode",
  props: [
    'phone'
  ],
  data() {
    return {
      text: '获取验证码',
      load: false,
      disable: false,
      timer: "",
      time: 60
    }
  },
  watch: {
    phone: function (val) {
      if (!/^1\d{10}$/.test(val)) {
        this.disable = true
      } else {
        this.disable = false
      }
    }
  },
  methods: {
    send() {
      if (!/^1\d{10}$/.test(this.phone)) {
        this.$message.warning("手机号格式不正确！")
        return
      }
      this.load = true
      this.$axios.post(this.$apiconfg.login_send_code, {account: this.phone}).then(({data}) => {
        this.load = false
        if (data.code === 1) {
          this.disable = true
          this.timer = setInterval(this.surplus, 1000);
        }
      }).catch(() => {
        this.load = false
      })


    },
    surplus() {
      if (this.time === 0) {
        this.text = '重新获取'
        this.time = 60
        this.disable = false
        clearInterval(this.timer)
      } else {
        this.disable = true
        this.text = "下次发送：" + (this.time -= 1) + "秒"
      }
    }
  }
}
</script>

<style scoped>
.send-btn-c {
  height: 30px;
  font-size: 12px;
  padding: 0 10px;
  min-width: 100px;
}
</style>