<template>
  <div>
    <el-empty style="width: 100%;height: 100vh;background-color: #ffffff;">
      <template #image>

        <svg t="1710837688410" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
          p-id="16112" width="200" height="200">
          <path
            d="M272.048 888c-48.528 0-88-39.488-88-88s39.472-88 88-88 88 39.488 88 88-39.472 88-88 88z m0-160c-39.696 0-72 32.304-72 72s32.304 72 72 72 72-32.304 72-72-32.304-72-72-72z"
            fill="#263238" p-id="16113"></path>
          <path
            d="M144 1016a8 8 0 0 1-8-8v-54.24c0-18.8 11.056-35.952 28.16-43.696l59.728-27.136v-13.712a8 8 0 0 1 16 0l0.016 18.88a8 8 0 0 1-4.688 7.28l-64.432 29.264A32 32 0 0 0 152 953.76V1008a8 8 0 0 1-8 8zM400 1016a8 8 0 0 1-8-8v-54.272c0-12.496-7.36-23.936-18.752-29.12l-64.672-29.408a8 8 0 0 1-4.688-7.28v-18.544a8 8 0 0 1 16 0v13.392l59.968 27.28a48.112 48.112 0 0 1 28.128 43.68V1008a7.984 7.984 0 0 1-7.984 8zM528.032 888c-48.528 0-88-39.488-88-88s39.472-88 88-88 88 39.488 88 88-39.472 88-88 88z m0-160c-39.696 0-72 32.304-72 72s32.304 72 72 72 72-32.304 72-72-32.288-72-72-72z"
            fill="#263238" p-id="16114"></path>
          <path
            d="M400 1016a8 8 0 0 1-8-8v-54.24c0-18.784 11.056-35.936 28.16-43.696l59.744-27.136v-13.712a8 8 0 0 1 16 0l0.016 18.88a8 8 0 0 1-4.688 7.28l-64.448 29.264a32 32 0 0 0-18.784 29.12V1008a8 8 0 0 1-8 8zM656 1016a8 8 0 0 1-8-8v-54.272c0-12.496-7.36-23.936-18.752-29.12l-64.656-29.408a8 8 0 0 1-4.688-7.28v-18.544a8 8 0 0 1 16 0v13.392l59.968 27.28a48.112 48.112 0 0 1 28.128 43.68V1008a8 8 0 0 1-8 8zM784.032 888c-48.528 0-88-39.488-88-88s39.472-88 88-88 88 39.488 88 88-39.472 88-88 88z m0-160c-39.696 0-72 32.304-72 72s32.304 72 72 72 72-32.304 72-72-32.288-72-72-72z"
            fill="#263238" p-id="16115"></path>
          <path
            d="M656 1016a8 8 0 0 1-8-8v-54.24c0-18.8 11.056-35.952 28.16-43.696l59.744-27.136v-13.712a8 8 0 0 1 16 0v18.88a8 8 0 0 1-4.688 7.28l-64.432 29.264a32 32 0 0 0-18.784 29.12V1008a8 8 0 0 1-8 8zM912 1016a8 8 0 0 1-8-8v-54.272c0-12.496-7.36-23.936-18.752-29.12l-64.656-29.408a8 8 0 0 1-4.688-7.28v-18.544a8 8 0 0 1 16 0v13.392l59.968 27.28a48.128 48.128 0 0 1 28.128 43.68V1008a8 8 0 0 1-8 8z"
            fill="#263238" p-id="16116"></path>
          <path
            d="M624.08 16h-240a40 40 0 0 0-40 40V176a40 40 0 0 0 40 40h40v45.84a16 16 0 0 0 26.24 12.288l69.76-58.128h104a40 40 0 0 0 40-40V56a40 40 0 0 0-40-40z"
            fill="#FF5252" p-id="16117"></path>
          <path
            d="M440.144 285.872c-3.456 0-6.944-0.768-10.24-2.304a23.808 23.808 0 0 1-13.824-21.728V224h-32c-26.464 0-48-21.536-48-48V56c0-26.464 21.536-48 48-48h240c26.464 0 48 21.536 48 48V176c0 26.464-21.536 48-48 48h-101.104l-67.52 56.272c-4.448 3.696-9.84 5.6-15.312 5.6zM384.08 24c-17.648 0-32 14.352-32 32V176c0 17.648 14.352 32 32 32h40a8 8 0 0 1 8 8v45.84c0 4.72 3.52 6.736 4.608 7.232 1.104 0.512 4.896 1.92 8.528-1.088l69.744-58.128a8 8 0 0 1 5.12-1.856h104c17.648 0 32-14.352 32-32V56c0-17.648-14.352-32-32-32h-240z"
            fill="#263238" p-id="16118"></path>
          <path
            d="M422.512 140c-13.232 0-24-10.768-24-24s10.768-24 24-24 24 10.768 24 24-10.768 24-24 24z m0-32a8 8 0 1 0 0.032 16.032 8 8 0 0 0-0.032-16.032zM502.512 140c-13.232 0-24-10.768-24-24s10.768-24 24-24 24 10.768 24 24-10.768 24-24 24z m0-32a8 8 0 1 0 0.032 16.032 8 8 0 0 0-0.032-16.032zM582.512 140c-13.232 0-24-10.768-24-24s10.768-24 24-24 24 10.768 24 24-10.768 24-24 24z m0-32a8 8 0 1 0 0.032 16.032 8 8 0 0 0-0.032-16.032zM952 671.456h-288c-13.232 0-24-10.768-24-24v-352c0-13.232 10.768-24 24-24h288c13.232 0 24 10.768 24 24v352c0 13.232-10.768 24-24 24z m-288-384a8 8 0 0 0-8 8v352a8 8 0 0 0 8 8h288a8 8 0 0 0 8-8v-352a8 8 0 0 0-8-8h-288z"
            fill="#263238" p-id="16119"></path>
          <path
            d="M840 575.456h-144a8 8 0 0 1 0-16h144a8 8 0 0 1 0 16zM808 615.456h-112a8 8 0 0 1 0-16h112a8 8 0 0 1 0 16zM808 535.456h-112a8 8 0 0 1 0-16h112a8 8 0 0 1 0 16zM248.08 408c-57.344 0-104-46.656-104-104s46.656-104 104-104 104 46.656 104 104-46.656 104-104 104z m0-192c-48.528 0-88 39.488-88 88s39.472 88 88 88 88-39.488 88-88-39.472-88-88-88z"
            fill="#263238" p-id="16120"></path>
          <path d="M314.64 248.496l7.152-5.952A96 96 0 0 0 152.08 304h9.056c56.096 0 110.4-19.648 153.504-55.504z"
            fill="#FFD740" p-id="16121"></path>
          <path
            d="M161.136 312h-9.056a8 8 0 0 1-8-8c0-57.344 46.656-104 104-104 30.912 0 60.016 13.648 79.856 37.408a8 8 0 0 1-1.024 11.264l-7.152 5.952v0.016a248.448 248.448 0 0 1-158.624 57.36z m-0.704-16h0.704c54.112 0 106.8-19.056 148.384-53.664l0.704-0.592a87.776 87.776 0 0 0-62.16-25.728 88.096 88.096 0 0 0-87.632 79.984z m154.224-47.52h0.16-0.16zM88 672a8 8 0 0 1-8-8v-142.528a87.552 87.552 0 0 1 48.672-78.736l67.68-33.808a8 8 0 0 1 7.152 14.32l-67.68 33.808A71.584 71.584 0 0 0 96 521.472V664a8 8 0 0 1-8 8z"
            fill="#263238" p-id="16122"></path>
          <path
            d="M152.08 672a8 8 0 0 1-8-8V560a8 8 0 0 1 16 0v104a8 8 0 0 1-8 8zM248 495.488a8 8 0 0 1-6.64-3.536l-48.064-71.408a7.92 7.92 0 0 1-1.36-4.464l-0.016-29.12a8.016 8.016 0 0 1 12.016-6.912 88.88 88.88 0 0 0 87.984 0.176 8.032 8.032 0 0 1 12 6.928l0.016 28.784a8 8 0 0 1-1.344 4.448l-47.936 71.568a8.032 8.032 0 0 1-6.656 3.536z m-40.048-81.84l40.048 59.504 39.952-59.648-0.016-13.504a104.608 104.608 0 0 1-80-0.128l0.016 13.776z"
            fill="#263238" p-id="16123"></path>
          <path
            d="M248 559.488a8 8 0 0 1-8-8v-64a8 8 0 0 1 16 0v64a8 8 0 0 1-8 8zM344.08 672a8 8 0 0 1-8-8l0.032-149.296a8 8 0 0 1 13.664-5.664l93.056 93.04c9.056 9.056 24.864 9.056 33.936 0l84.848-84.848-33.936-33.936-62.224 62.224a8 8 0 0 1-11.312 0L392 483.392a151.984 151.984 0 0 0-36.416-26.88l-63.376-33.52a8 8 0 0 1 7.488-14.16l63.376 33.52a168.224 168.224 0 0 1 40.24 29.712l56.496 56.48 62.224-62.224a8 8 0 0 1 11.312 0l45.248 45.248a8 8 0 0 1 0 11.328l-90.512 90.496c-15.104 15.12-41.456 15.12-56.56 0l-79.408-79.392-0.032 130a8 8 0 0 1-8 8zM595.552 502.624a8 8 0 0 1-5.664-13.648 24.064 24.064 0 0 0 0-33.952 24.064 24.064 0 0 0-33.952 0 8 8 0 0 1-11.328-11.312 40.048 40.048 0 0 1 56.576 0 40.048 40.048 0 0 1 0 56.576 7.904 7.904 0 0 1-5.632 2.336z"
            fill="#263238" p-id="16124"></path>
          <path d="M768 399.44m-64 0a64 64 0 1 0 128 0 64 64 0 1 0-128 0Z" fill="#40C4FF" p-id="16125"></path>
          <path
            d="M768 471.456c-39.696 0-72-32.304-72-72s32.304-72 72-72 72 32.304 72 72-32.304 72-72 72z m0-128c-30.88 0-56 25.12-56 56s25.12 56 56 56 56-25.12 56-56-25.12-56-56-56z"
            fill="#263238" p-id="16126"></path>
          <path d="M848 399.44m-64 0a64 64 0 1 0 128 0 64 64 0 1 0-128 0Z" fill="#40C4FF" p-id="16127"></path>
          <path
            d="M848 471.456c-39.696 0-72-32.304-72-72s32.304-72 72-72 72 32.304 72 72-32.304 72-72 72z m0-128c-30.88 0-56 25.12-56 56s25.12 56 56 56 56-25.12 56-56-25.12-56-56-56z"
            fill="#263238" p-id="16128"></path>
          <path d="M784 399.44a49.584 24 90 1 0 48 0 49.584 24 90 1 0-48 0Z" fill="#40C4FF" p-id="16129"></path>
          <path
            d="M808 457.024a7.968 7.968 0 0 1-5.024-1.776 71.6 71.6 0 0 1-26.976-55.792 71.52 71.52 0 0 1 26.976-55.808 8.016 8.016 0 0 1 10.064 0 71.6 71.6 0 0 1 26.976 55.808 71.52 71.52 0 0 1-26.976 55.792 8.048 8.048 0 0 1-5.04 1.776z m0-96.368c-10.24 10.448-16 24.272-16 38.8s5.76 28.336 16 38.784c10.24-10.448 16-24.256 16-38.784s-5.76-28.352-16-38.8zM200 110.944h-64a8 8 0 0 1 0-16h64a8 8 0 0 1 0 16z"
            fill="#263238" p-id="16130"></path>
          <path d="M168 142.944a8 8 0 0 1-8-8v-64a8 8 0 0 1 16 0v64a8 8 0 0 1-8 8z" fill="#263238" p-id="16131"></path>
          <path d="M120 198.944h-64a8 8 0 0 1 0-16h64a8 8 0 0 1 0 16z" fill="#263238" p-id="16132"></path>
          <path d="M88 230.944a8 8 0 0 1-8-8v-64a8 8 0 0 1 16 0v64a8 8 0 0 1-8 8z" fill="#263238" p-id="16133"></path>
          <path d="M872 223.456h-160a8 8 0 0 1 0-16h160a8 8 0 0 1 0 16z" fill="#263238" p-id="16134"></path>
          <path d="M920 223.44h-16a8 8 0 0 1 0-16h16a8 8 0 0 1 0 16z" fill="#263238" p-id="16135"></path>
        </svg>
      </template>

      <template #description>
        <p style="font-size: 16px;color: #666666;">测评结果有更新，请查看最新测评结果!</p>
      </template>
      <a href="https://report.dichan.com" target="_blank"><el-button>查看最新测评报告</el-button></a>
    </el-empty>
  </div>
</template>


<style lang="less" scoped></style>

<script>

export default {
  name: "Index",
  data() {
    return {
      city: null,
      year: null,
      category: null,
      list: null,
      load: {
        where: false
      },
      where: {
        city: null,
        year: null,
        category: null
      },
      copywriting: null
    }
  },
  metaInfo: {
    title: '新测评报告提示-优采云大数据服务平台',
    htmlAttrs: {
      lang: 'zh-CN',
      keyword: '优采云，大数据，云服务，区域测评，报告',
      amp: true
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>
