import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isLogin: false,
        auth: null,
        code: ''
    },
    mutations: {
        Login(state, user) {
            if (user) {
                state.isLogin = true
                state.auth = user
            }
            console.log('登录成功~')
        },
        loginOut(state) {
            state.isLogin = false
            state.auth = null
        },
        saveCode(state, code) {
            this.state.code = code
        }
    },
    actions: {},
    modules: {},
    plugins: [createPersistedState()]
})
