<template>
  <div class="vue-body login-main clearfix">
    <div class="left">
    </div>
    <div class="right">
      <div class="login-box">
        <img src="@/assets/images/logo_guide.png"/>
        <div>
          <el-form ref="ruleForm" :model="form" :rules="rules" class="youcai-login-form">
            <el-form-item prop="account">
              <el-input v-model.trim="form.account" maxlength="11" placeholder="请输入用户手机号码"
                        prefix-icon="el-icon-mobile-phone"></el-input>

            </el-form-item>
            <el-form-item class="code-send" prop="code">
              <el-input v-model.trim="form.code" maxlength="6" placeholder="请输入短信验证码"
                        prefix-icon="el-icon-unlock" ></el-input>
              <span class="send-btn">
                <send-code :phone="form.account"></send-code>
              </span>
              <div class="login-tips-box">
                <el-checkbox v-model="isRemember">记住登录账号</el-checkbox>
                <span class="tips-register">
                  <a href="//youcaiyun.com/Pages/Account/Regist" target="_blank">还没有账号？前往注册</a> 或 <a
                    href="//youcaiyun.com/AttestationMember" target="_blank">去认证</a>
                </span>
              </div>
            </el-form-item>
            <el-form-item>
              <el-button class="block-btn" plain type="danger" @click="login('ruleForm')">立即登录</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="less" scoped>
@import '@/assets/css/public.less';

.vue-body {
  height: 100vh;
  overflow: hidden;
  background: rgb(247, 250, 250); /* fallback for old browsers */
  position: relative;
}

.login-main > div {
  width: 50%;
  float: left;
  height: 100vh;
  overflow: hidden;

}

.login-main .left {
  background: url("~@/assets/images/login_bg.png") no-repeat;
  background-position: center;
}

.login-main .right {
  min-width: 800px;
  background: #FFFFFF;
  -webkit-clip-path: polygon(25% 0%, 100% 0, 100% 100%, 25% 100%, 0% 50%);
  clip-path: polygon(25% 0%, 100% 0, 100% 100%, 25% 100%, 0% 50%);
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
}

.login-box {
  height: 420px;
  width: 380px;
  position: absolute;
  left: 35%;
  top: calc((100% - 420px) / 2);
  box-sizing: border-box;
  text-align: center;
  padding: 10px;
}

.youcai-login-form {
  margin-top: 40px;
}

.code-send {
  position: relative;
}

.send-btn {
  position: absolute;
  top: 0;
  right: 5px;
}

.youcai-login-form /deep/ .el-form-item {
  margin-bottom: 40px;
}

.login-tips-box {
  text-align: left;
  font-size: 12px;
  color: @color-info;
}

.tips-register {
  margin-left: 10px;
  float: right;
}

.tips-register a {
  font-size: 12px;
  color: @color-danger;
}

.send-btn.disable {
  cursor: no-drop;
  opacity: .3;
}
</style>

<script>

import SendCode from "../components/SendCode";

export default {
  name: "Login",
  components: {SendCode},
  metaInfo: {
    title: 'TOP500房建供应链首选供应商服务商品牌测评研究报告-请登录',
    htmlAttrs: {
      lang: 'zh-CN',
      keyword:'优采云，大数据，云服务，区域测评，报告',
      amp: true
    }
  },
  data() {
    var validatePhone = (rule, value, callback) => {
      if (!/^1\d{10}$/.test(value)) {
        this.text.disable = true
        callback(new Error('手机号格式不正确'))
      } else {
        this.$axios.post(this.$apiconfg.login_check, this.form).then(({data}) => {
          if (data.code === 1) {
            this.text.disable = false
            callback()
          } else {
            callback(new Error(data.msg))
          }
        })


      }
    };
    return {
      uri: this.$route.params.uri ?? '/',
      isRemember: false,
      code: '',
      form: {
        account: "",
        code: ""
      },
      text: {
        sendmsg: "获取验证码",
        disable: true,
        surplus: 5,
        msurplus: null,
        load: false
      },
      rules: {
        account: [
          {required: true, message: '请输入手机号，示例：185xxxx1234', trigger: 'change'},
          {validator: validatePhone, trigger: 'change'}
        ],
        code: [
          {required: true, message: '请输入短信验证码：4-6位', trigger: 'change'},
          {min: 4, max: 6, message: '短信验证码格式不正确：4-6位', trigger: 'change'},
        ]
      }
    }
  },
  mounted() {

    if (this.$store.state.code.length > 8) {
      this.form.account = this.$store.state.code
      this.text.disable = false
      this.isRemember = true
    }
  },
  methods: {
    login(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {

          this.$axios.post(this.$apiconfg.login, this.form).then(({data}) => {
            if (data.code === 1) {
              this.$store.commit('Login', data.info)
              if (this.isRemember) {
                this.$store.commit('saveCode', this.form.account)
              } else {
                this.$store.commit('saveCode', "")
              }
              this.$router.replace(this.uri)

            }

          })

        } else {
          return false;
        }
      });
    },
    send_code() {
      if (this.text.disable) {
        this.$message.warning('请勿频繁操作！')
        return
      }

      if (this.text.load) {
        return
      }

      this.text.load = true
      this.$axios.post(this.$apiconfg.login_send_code, this.form).then(({data}) => {
        if (data.code === 1) {
          this.text.msurplus = setInterval(() => {
            if (this.text.surplus < 1) {
              this.text.sendmsg = '重新发送'
              this.text.disable = false
              this.text.surplus = 5
              //清除定时器
              this.text.msurplus = null
            } else {
              this.text.sendmsg = "剩余：" + (this.text.surplus -= 1) + "秒"
              this.text.disable = true
            }
          }, 1000)
          this.text.load = false
        }
      })
    }
  }
}
</script>
