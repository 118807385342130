<template>
  <div>
    <div class="ps-relative">
      <header>
        <el-row class="main">
          <el-col :span="5">
            <img alt="LOGO" class="logo" src="@/assets/images/logo_guide.png">
          </el-col>
          <el-col :span="14" class="link-to-list">
            <a target="_blank" href="https://youcaiyun.com/">优采云大数据</a>
            <a target="_blank" href="https://youcaiyun.com/WenBrigadeBidList">最新招标</a>
            <a target="_blank" href="https://youcaiyun.com/VIPMemberPay">招采VIP</a>
            <a target="_blank" href="https://study.youcaiyun.com/">云学院</a>
            <a target="_blank" href="https://xiazai.dichan.com/">地产智库</a>
          </el-col>
          <el-col :span="5" class="user-bar">
            <div v-if="!this.$store.state.isLogin" class="no-login">
              <span>
                <router-link to="/login">登录</router-link>
              </span>
              <span>
                <a target="_blank" href="https://youcaiyun.com/Pages/Account/Regist">注册</a>
              </span>
            </div>
            <div v-else class="user-bar-islogin">
              <div style="height: 40px;line-height: 40px">
                您好，{{ this.$store.state.auth.RealName }}（{{ this.$store.state.auth.Phone }}） <span
                  class="logout-btn-text" @click="logout">注销</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </header>
      <el-carousel class="header-banner" height="400px">
        <el-carousel-item v-for="item in banner" :key="item.src">
          <a :href="item.url" :target="item.target">
            <img :src="item.src" class="banner-top" />
          </a>
        </el-carousel-item>
      </el-carousel>

    </div>
    <el-dialog :visible.sync="show.login" class="mini-padding-d" width="420px">
      <span slot="title">
        <div class="center">
          <img src="~@/assets/images/logo_guide.png" />
        </div>
        <div class="user-tips">
          登录
        </div>
      </span>
      <el-form :model="form" label-position="top" label-width="90px">
        <el-form-item label="账号" prop="phone">
          <el-input v-model="form.phone" maxlength="11" placeholder="请输入手机号码"
            prefix-icon="el-icon-mobile-phone"></el-input>
        </el-form-item>
        <el-form-item label="短信验证码" prop="code">
          <el-col :span="16">
            <el-input v-model="form.code" maxlength="6" placeholder="请输入4-6位短信验证码"
              prefix-icon="el-icon-unlock"></el-input>
          </el-col>
          <el-col :span="8" style="text-align: right">
            <el-button type="success">获取验证码</el-button>
          </el-col>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button class="block-btn" style="height: 50px" type="primary">立即登录</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: "Header",
  data() {
    return {
      banner: [],
      show: {
        login: false
      },
      form: {
        phone: '',
        code: ''
      },
    }
  },
  created() {
    this.$axios.post(this.$apiconfg.banner).then(({ data }) => {
      this.banner = data.data.web
    })
  },
  mounted() {
    if (this.$route.query.needlogin && !this.$store.state.isLogin) {
      this.show.login = true
      setTimeout(() => {
        this.$notify.warning({
          title: "请登录~",
          message: '请登录后继续操作'
        })
      }, 300)

    }
  },
  methods: {
    logout() {
      this.$axios.post(this.$apiconfg.login_out, {
        option: 'login_out'
      }).then(() => {
        this.$store.commit('loginOut')
        this.$router.push('/login')
      })

    },
    show_login() {
      this.show.login = true
    }
  }
}
</script>

<style lang="less" scoped>
@import '@/assets/css/public.less';

header {
  height: 50px;
  padding: 20px 0;
  color: #FFFFFF;
  background: transparent;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
}

.header-banner {
  width: 100%;
  position: relative;
  z-index: 0;
}

.header-banner /deep/ .banner-top {
  width: 100%;
  min-width: 1200px;
  object-fit: cover;
  height: 400px;
  margin: 0 auto;
  display: block;
}

.logo {
  height: 40px;
}

.link-to-list {
  padding-left: 10px;
  line-height: 40px;
  height: 40px;
  box-sizing: border-box;
}

.link-to-list a {
  margin-right: 15px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  text-decoration: none;
  transition: all ease .3s;

}

.link-to-list a:hover {
  background: @color-red;
  color: #ffffff;
  border-radius: 3px;
  padding: 4px 8px 6px 8px;
}

.user-bar {
  text-align: right;

}

.user-bar .no-login>span {
  height: 40px;
  line-height: 38px;
  margin-right: 10px;
  font-size: 13px;
  cursor: pointer;
}

.user-bar .no-login>span:last-child {
  margin-right: 0;
}

.user-bar .no-login a {
  font-size: 13px;
  color: #ffffff;
  text-decoration: none;
}

.user-bar-islogin {
  font-size: 12px;
}

.logout-btn-text {
  padding: 2px 5px 5px 5px;
  font-size: 12px;
  border-radius: 3px;
  cursor: pointer;
}

.logout-btn-text:hover {
  background: @color-red;
}

.user-tips {
  text-align: center;
  font-size: 25px;
  letter-spacing: 2px;
  font-weight: 300;
  margin-top: 25px;
  color: @color-info;
}

.mini-padding-d /deep/ .el-dialog__body {
  padding: 10px 20px;
}
</style>