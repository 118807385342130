import Vue from "vue";
import axios from "axios";
import router from "../router";
import { Notification } from "element-ui";
import store from "../store";

Vue.prototype.$axios = axios;
Vue.prototype.$apiconfg = {
  index_config: "/api/index_config", //首页筛选
  index: "/api/index", //首页
  login: "/api/login_post", //登录
  login_out: "/api/login_out", //退出
  login_send_code: "/api/login_send_code", //发送短信
  login_check: "/api/login_check", //验证是否有权限操作
  collection: "/api/collection", //收藏
  collection_update: "/api/collection_update", //收藏状态调整
  collection_check: "/api/collection_check", //收藏状态调整
  connect_check: "/api/connect_check", //标记（联系）
  connect_add: "/api/connect_add", //添加标记
  wechat: "/api/wechat", //微信分享签名包
  banner: "/api/banner", //Banner
};

if (process.env.NODE_ENV === "development") {
  axios.defaults.baseURL = "http://api.ycrank.test/";
} else {
  axios.defaults.baseURL = "https://api-report.dichan.com";
}

axios.interceptors.request.use(function(config) {
  var Token = "token  fail && not authorization login";
  if (store.state.isLogin && store.state.auth.token) {
    Token = store.state.auth.token ? store.state.auth.token : "not token fail";
  }
  config.headers.Authorization = Token;
  return config;
});

// 添加响应拦截器
var notLoginNot = null;
axios.interceptors.response.use(
  function(response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    var data = response.data;
    switch (data.code) {
      case 401:
        if (notLoginNot) notLoginNot.close();
        notLoginNot = Notification.warning({
          title: "您的登录好像过期了",
          message: "请重新登录",
          duration: 1000 * 5,
        });
        store.commit("loginOut");
        router.push({ name: "Login" });
        break;
      case 1:
        if (data.msg !== "success" && data.is_pop) {
          Notification.success({
            title: data.msg,
            message: "处理结果：OK",
            duration: 1000 * 5,
          });
        }
        break;
      default:
        if (data.is_pop) {
          Notification.error({
            title: data.msg,
            message: "处理结果：Fail",
            duration: 1000 * 5,
          });
        }
        break;
    }

    return response;
  },
  function(error) {
    Notification.error({
      title: "请求Error",
      message: "网络连接失败，请稍后再试",
      duration: 1000 * 5,
    });
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);
