import Vue from 'vue'
import App from './App.vue'
import VueMeta from 'vue-meta'


import store from './store'
import router from './router'
/*公共样式*/
import '@/assets/css/public.less'
import './plugins/element.js'
import './plugins/axios.js'
import lodash from 'lodash'
Vue.use(VueMeta, {
    // optional pluginOptions
    refreshOnceOnNavigation: true
})


Vue.config.productionTip = false
Vue.prototype.$lodash = lodash
//一维数组JSON 通过Key 获取指定值
Vue.prototype.$getJsonForKey = function (json, value, key = 'value') {
    function ine(item) {
        for (let i in item) {
            if (json[i][key] === value) {
                return json[i]
            } else{
                if (json[i].children){
                   return  ine(json)
                }
            }
        }

        return  {label:"未匹配参数-undefined"}
    }
    return  ine(json)
}



new Vue({
    store,
    router,
    lodash,
    render: h => h(App)
}).$mount('#app')


