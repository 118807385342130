<template>
  <div>
    <div class="top">

      <div class="top-bar">
        <img src="~@/assets/images/2024/filter.png" class="icon_left_t">测评筛选
        <span class="goto-more">
          <!-- <a href="//rank.dichan.com/" target="_blank">全国测评报告 <i class='el-icon-arrow-right'></i></a> -->
        </span>
      </div>

      <div v-loading="load.where" class="where" element-loading-background="rgba(255,255,255,.1)">
        <div class="row-top">
          <div class="left">区域：</div>
          <div class="right dark">
            <span v-for="item in city" :key="item.name" :class="where.city === item.name ? 'cur' : ''"
              @click="change_city(item)">{{ item.name }}</span>
            <span v-if="!city">-</span>
          </div>
        </div>

        <div class="row-top">
          <div class="left">年份：</div>
          <div class="right dark">
            <span v-for="item in year" :key="item.name" :class="where.year === item.year ? 'cur' : ''"
              @click="change_year(item)">{{ item.label }}</span>
            <span v-if="!year">-</span>
          </div>
        </div>

        <div class="row-top">
          <div class="left">品类：</div>
          <div class="right ">
            <span v-for="item in category" :key="item.name" :class="where.category === item.id ? 'cur' : ''"
              @click="change_category(item)">{{item.title}}</span>
            <span v-if="!category || category.length < 1">暂无匹配行业</span>
          </div>
        </div>
      </div>

    </div>

    <div class="section">
      <div class="title">
        {{ where.city }} - {{ where.year }}年度首选品牌测评报告
        <span class="my-coll">
          <a v-if="this.$store.state.auth && this.$store.state.auth.hall.industry === 2"
            href="https://youcaiyun.com/WenBrigadeBidList" target="_blank">热门招标 <i class='el-icon-arrow-right'></i></a>
          <router-link v-else to="/Reserve">我的储备库 <i class='el-icon-arrow-right'></i></router-link>
        </span>
      </div>
      <div v-if="list" class="list">
        <div class="item">
          <div class="center" v-if="list.data.length > 0">
            <span class="eo-title"> {{ list.title }} </span>
          </div>
          <dl class="data-list">


            <dd v-for="item in list.data" :key="item.id" class="clearfix">
              <div>
                TOP {{ item.show_sort }}
              </div>
              <div>
                <a v-if="item.company" :href="item.company.hall ? item.company.hall.url : '#'" target="_blank">
                  <el-image :src="item.company.logo" class="logo" fit="contain" lazy>
                    <div slot="error" style="display: flex;align-items: center;height: 100%;">
                      <div class="logo-txt">{{ item.rank_brand ||
        item.company_name }}</div>
                    </div>
                  </el-image>
                </a>
                <el-image v-else class="logo" fit="contain" lazy>
                  <div slot="error" style="display: flex;align-items: center;height: 100%;">
                    <div class="logo-txt">{{ item.rank_brand || item.company_name }}</div>
                  </div>
                </el-image>
              </div>
              <div>
                <div class="company-title">
                  <a v-if="item.company" :href="item.company.hall ? item.company.hall.url : '#'" target="_blank">{{
        item.company_name || item.company.CompanyName
      }}
                    <span class="more-goto">前往企业展厅<i class="el-icon-arrow-right"></i></span>
                  </a>
                  <div v-else>
                    <span>{{ item.company_name }}</span>
                    <span class="ref-hall">
                      <el-tooltip placement="top">
                        <div slot="content">通过优采企业认证后可管理该企业信息<br />如果您已经认证，请耐心等待数据更新</div>
                        <span>
                          <a href="https://youcaiyun.com/Pages/Account/Login" target="_blank">认证展厅</a>
                          <i class="el-icon-question"></i></span>
                      </el-tooltip>
                    </span>
                  </div>

                </div>
                <div class="tag-box">
                  <dl class="tag-cp" v-if="item.rank_brand">{{ item.rank_brand }}</dl>
                  <dl class="tag-cp">{{ list.interpretive_prefix }} <span class="uit">{{ item.unit_val }}{{
        list.interpretive_unit }}</span></dl>
                  <template v-if="item.company">
                    <dl class="tag-cp tag-cp-mini" v-for="tags in item.company.tags" :key="tags.title"
                      v-show="tags.value > 0">{{ tags.title }}
                      <span class="uit">{{ tags.value }}</span>
                    </dl>
                  </template>

                  <!-- <span v-for="tags in item.company.tags" :key="tags.title" class="tag-cp">{{tags.title}}<span>{{ tags.value }}</span></span> -->
                </div>
              </div>
              <div class="btn-box" v-if="item.company">
                <collection :company_id="item.company.CompanyId"></collection>
                <connect :company="item.company"></connect>
              </div>

            </dd>

          </dl>
        </div>
        <!-- 子分类 -->
        <div v-for="child in list.child" :key="child.id" class="item">
          <div v-if="child.data.length > 0" class="center">
            <span class="eo-title">子分类 - {{ child.title }} </span>
          </div>
          <dl class="data-list">
            <dd v-for="item in child.data" :key="item.id" class="clearfix">
              <div>
                TOP {{ item.show_sort }}
              </div>
              <div>
                <a v-if="item.company" :href="item.company.hall ? item.company.hall.url : '#'" target="_blank">
                  <el-image :src="item.company.logo" class="logo" fit="contain" lazy>
                    <div slot="error" style="display: flex;align-items: center;height: 100%;">
                      <div class="logo-txt">{{ item.rank_brand ||
        item.company_name }}</div>
                    </div>
                  </el-image>
                </a>
                <el-image v-else class="logo" fit="contain" lazy>
                  <div slot="error" style="display: flex;align-items: center;height: 100%;">
                    <div class="logo-txt">{{ item.rank_brand ||
        item.company_name }}</div>
                  </div>
                </el-image>
              </div>
              <div>
                <div class="company-title">
                  <a v-if="item.company" :href="item.company.hall ? item.company.hall.url : '#'" target="_blank">{{
        item.company_name || item.company.CompanyName
      }}
                    <span class="more-goto">前往企业展厅<i class="el-icon-arrow-right"></i></span>
                  </a>
                  <div v-else>
                    <span>{{ item.company_name }}</span>
                    <span class="ref-hall">
                      <el-tooltip placement="top">
                        <div slot="content">通过优采企业认证后可管理该企业信息<br />如果您已经认证，请耐心等待数据更新</div>
                        <span>
                          <a href="https://youcaiyun.com/Pages/Account/Login" target="_blank">认证展厅</a>
                          <i class="el-icon-question"></i></span>
                      </el-tooltip>
                    </span>
                  </div>
                </div>
                <div class="tag-box">
                  <dl class="tag-cp" v-if="item.rank_brand">{{ item.rank_brand }}</dl>
                  <dl class="tag-cp">{{ child.interpretive_prefix }} <span class="uit">{{ item.unit_val }}{{
        child.interpretive_unit }}</span></dl>

                  <template v-if="item.company">
                    <dl class="tag-cp tag-cp-mini" v-for="tags in item.company.tags" :key="tags.title"
                      v-show="tags.value > 0">
                      {{ tags.title }}<span class="uit">{{ tags.value }}</span>
                    </dl>
                  </template>
                </div>
              </div>
              <div class="btn-box" v-if="item.company">
                <collection :company_id="item.company.CompanyId"></collection>
                <connect :company="item.company"></connect>
              </div>

            </dd>

          </dl>
        </div>


      </div>

      <el-empty v-else description="暂无匹配数据，敬请期待!" style="min-height: 500px"></el-empty>

    </div>

    <div v-if="copywriting" class="is-background">

      <div class="center">
        <span class="bg-title"> 测评背景 </span>
      </div>
      <div class="is-background-p is-background-y" v-html="copywriting.text"></div>
    </div>

    <div v-if="copywriting" class="is-background sce-bg">
      <div class="center">
        <span class="bg-title"> 组织机构 </span>
      </div>
      <el-row v-for="item in copywriting.mechanism" :key="item.title" class="item-unit">
        <el-col :span="4" class="ps-relative">
          <span class="un-it">{{ item.type }}</span>
          <img :src="item.logo" class="logo-unit">
        </el-col>
        <el-col :span="19">
          <div class="is-background-p" v-html="item.text">
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="center mg-20-top">
      <img src="~@/assets/images/logo_guide.png">
      <div class="tips-footer">
        沪ICP备16028066号-2
      </div>
      <div class="tips-footer">
        需要帮助？联系客服 400-6188989
      </div>
    </div>

  </div>
</template>

<style lang="less">
@import '@/assets/css/public.less';

body {
  background: @color-index-bg;
}
</style>

<style lang="less" scoped>
@import '@/assets/css/public.less';

.top {
  color: #8F0030;
  box-sizing: border-box;
  margin: 10px auto;
  max-width: 1200px;
  min-width: 970px;
  padding: 10px;
  background: linear-gradient(90deg, #FFEEE9 0%, #FFF7F5 50%, #FFEFEC 100%);
  border-radius: 3px;
}

.top-bar {
  box-sizing: border-box;
  font-size: 13px;
  border-bottom: 1px solid @color-index-font-light;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}

.goto-more,
.goto-more a {
  font-size: 12px;
  float: right;
  color: #FFFFFF;
  text-decoration: none;
}

.goto-more,
.goto-more a:hover {
  color: @color-index-font-light;
}

.where {
  font-size: 12px;
  padding-top: 10px;
}


.where .right {
  padding-left: 3px;
  color: @color-index-type;
}

.where .left {
  margin-bottom: 10px;
  letter-spacing: 4px;
  font-size: 12px;
  color: #8F0030;
}

.where .right>span {
  display: inline-block;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  letter-spacing: 1px;
  cursor: pointer;
}

.where .right>span:hover {
  color: @color-index-font-light-lv1;
  transition: all ease .3s;
}

.where .cur {
  color: #B77140;
  font-weight: bold;
}

.where .right.dark>span:hover {
  color: @color-index-font-light;
  transition: all ease .3s;
}

.where .dark .cur {
  color: #B77140;
  font-weight: 550;
}

.section {
  margin: 20px auto;
  box-sizing: border-box;
  padding: 10px;
  max-width: 1200px;
  min-width: 970px;
  background: #FFEFEC;
  color: #ffffff;
}

.section .title {
  font-size: 18px;
  padding-bottom: 10px;
  border-bottom: 1px solid #FFDED4;
  letter-spacing: 1px;
  color: #8F0030;
}


.section .list .eo-title {
  font-size: 20px;
  color: #8F0030;
  font-weight: bold;
  letter-spacing: 1.5px;
}

.eo-title::after {
  content: " ";
  width: 30px;
  display: inline-block;
  height: 12px;
  background: url("~@/assets/images/2024/rank-right.png") no-repeat;
  background-size: contain;
}

.eo-title::before {
  content: " ";
  width: 30px;
  display: inline-block;
  height: 12px;
  background: url("~@/assets/images/2024/rank-left.png") no-repeat;
  background-size: contain;
  background-position: right;
  margin-right: 7px;
}

.section .list .data-list {
  padding: 0;
  margin: 20px 0 0 0;
}

.section .list .data-list dd {
  margin: 0 0 20px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #F2CDC3;
  padding-bottom: 20px;
}

.section .list .data-list dd:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.section .list .data-list dd>div {
  float: left;
  margin-right: 10px;
}

.section .list .data-list dd>div:nth-child(1) {
  width: 80px;
  font-weight: 400;
  text-indent: 10px;
  color: #5A311E;
  font-family: HYRuiZhi-W, HYRuiZhi;
}

.section .list .data-list dd>div:nth-child(2) {
  width: 160px;
}

.section .list .data-list dd>div:nth-child(3) {
  width: calc(100% - 10px * 3 - 80px - 160px - 240px);
}

.section .list .data-list dd>div:nth-child(4) {
  width: 240px;
  text-align: right;
}

.data-list .logo {
  width: 133px;
  height: 70px;
  background: #FFFFFF;
  opacity: 1;
  border-radius: 4px;
  object-fit: contain;
  padding: 5px 10px;
}

.data-list .company-title,
.data-list .company-title a {
  font-size: 16px;
  letter-spacing: 2px;
  color: #5D2811;
  text-decoration: none;
  font-weight: bold;
}

.data-list .tag-box {
  margin-top: 15px;
}

.data-list .tag-box>span {
  margin-right: 10px;
}

.is-background-p {
  margin-top: 30px;
}

.is-background-p /deep/ p {
  font-size: 12px;
  text-indent: 2rem;
  letter-spacing: 1.2px;
  line-height: 1.25rem;
  color: #8F0030;
}

.data-list .tag-box>span:last-child {
  margin-right: 0;
}

.data-list .tag-box .tag-cp {
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  background: #FEEBDE;
  border-radius: 2px;
  overflow: hidden;
  display: inline-block;
  min-width: 4rem;
  vertical-align: middle;
  text-align: center;
  height: 22px;
  padding: 0 5px;
  line-height: 22px;
  margin: 0 10px 0 0;
}

.data-list .tag-box .tag-cp .uit {
  display: inline-block;
  height: 22px;
  line-height: 22px;
  color: #F04035;
  min-width: 2.5rem;
  text-align: center;
}


.data-list .tag-box .tag-cp-mini .uit {
  display: inline-block;
  height: 22px;
  line-height: 22px;
  color: #F04035;
  min-width: 1.5rem;
  text-align: center;
}

.data-list .btn-box>span {
  margin-right: 15px;
}

.data-list .btn-box>span:last-child {
  margin-right: 0;
}

.data-list .company-title .more-goto {
  opacity: 0;
  font-size: 12px;
  transition: all ease .3s;
}

.data-list>dd:hover .company-title .more-goto {
  opacity: 1;
  color: @color-danger;
}

.section .list .item {
  margin-top: 80px;
}

.is-background {
  max-width: 1200px;
  min-width: 970px;
  margin: 15px auto;
  padding: 20px;
  background: linear-gradient(180deg, #FEF5ED 0%, #FFF2E6 100%);
  box-sizing: border-box;
  border-radius: 2px;
  position: relative;
}

.is-background-y::after {
  content: "";
  display: block;
  position: absolute;
  width: 25px;
  height: 25px;
  background: url("~@/assets/images/path_1.png") no-repeat;
  background-size: contain;
  left: 30px;
  top: 50px;
}

.is-background-y::before {
  content: "";
  display: block;
  position: absolute;
  width: 25px;
  height: 25px;
  background: url("~@/assets/images/path_2.png") no-repeat;
  background-size: contain;
  right: 30px;
  top: 50px;
}

.is-background p {
  font-size: 12px;
  text-indent: 2rem;
  letter-spacing: 1.2px;
  line-height: 1.25rem;
  color: rgba(223, 197, 176, 1);
}

.organization {
  margin: 15px auto;
  color: #FFFFFF;
  padding: 20px 10px;
  background: @color-index-bg-lv1;
  box-sizing: border-box;
  border-radius: 2px;
}

.bg-title {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1.5px;
  color: #85461a;
  position: relative;
}


.bg-title::before {
  content: " ";
  display: block;
  position: absolute;
  width: 82px;
  height: 5px;
  background: linear-gradient(90deg, #F04035 0%, rgba(240, 64, 3, .2) 80%, #FEF4EB 100%);
  bottom: -10px;
  left: 0;
}

.logo-unit {
  padding: 10px;
  background: #FFFFFF;
  object-fit: contain;
  height: 80px;
  width: 158px;
}

.item-unit {
  margin: 60px auto;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
}

.item-unit p {
  font-size: 12px;
  letter-spacing: 1.2px;
  line-height: 1.25rem;
  color: rgba(223, 197, 176, 1);
}

.un-it {
  position: absolute;
  border-radius: 8px 0 0 0;
  height: 25px;
  box-sizing: border-box;
  padding: 0 15px;
  line-height: 25px;
  background: @color-index-font-light;
  font-size: 12px;
  left: 0;
  top: -25px;
  color: @color-index-font-light-dark;
  letter-spacing: 1px;
}

.tips-footer {
  font-size: 12px;
  color: #FFFFFF;
  margin: 10px auto;
}

.my-coll {
  font-size: 12px;
  float: right;
  height: 30px;
  line-height: 25px;
}

.my-coll a {
  text-decoration: none;
  color: #F04035;
  letter-spacing: 0;
}

.my-coll a:hover {
  text-decoration: underline;
}

.ref-hall,
.ref-hall a {
  text-decoration: none;
  font-size: 12px !important;
  margin-left: 5px;
  display: none;
}

.data-list>dd:hover .ref-hall,
.data-list>dd:hover .ref-hall a {
  display: inline-block;
  color: orangered;
}

.sce-bg {
  position: relative;
}

.sce-bg::after {
  content: "";
  display: block;
  position: absolute;
  width: 510px;
  height: 100%;
  background: url("~@/assets/images/sclae.png") no-repeat;
  background-size: contain;
  z-index: 0;
  right: 0;
  top: 0;
}

.logo-txt {
  font-size: 15px;
  color: #666666;
  width: 100%;
  text-align: center;
  font-weight: bold;
  letter-spacing: 1px;
}

.icon_left_t {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}

.row-top {
  display: grid;
  // 2列
  grid-template-columns: 3rem auto;
}
</style>

<script>
import Collection from "../components/Collection";
import Connect from "../components/Connect";

export default {
  name: "Index",
  components: { Connect, Collection },
  data() {
    return {
      city: null,
      year: null,
      category: null,
      list: null,
      load: {
        where: false
      },
      where: {
        city: null,
        year: null,
        category: null
      },
      copywriting: null
    }
  },
  metaInfo: {
    title: '2023房建供应链首选供应商服务商品牌测评研究报告-优采云大数据服务平台',
    htmlAttrs: {
      lang: 'zh-CN',
      keyword: '优采云，大数据，云服务，区域测评，报告',
      amp: true
    }
  },
  mounted() {

    this.init()

    this.$axios.post(this.$apiconfg.index_config).then(({ data }) => {
      if (data.code === 1) {
        this.copywriting = data.data
      }
    })

    //微信分享
    var wx = require('weixin-js-sdk');
    this.$axios.post(this.$apiconfg.wechat, {
      url: location.href
    }).then(({ data }) => {
      if (data.code === 1) {
        const config = data.data
        wx.config(config)
        wx.ready(function () {
          console.log('微信分享Ready-ok')

          const share_data = {
            title: '2024房建供应链企业首选供应商服务商品牌测', // 分享标题
            desc: '测评是房建领域工程采购招投标及战略合作的重要参考。聚焦4大领域，7大品类，超过150个子项。', // 分享描述
            link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://api-report.dichan.com/static/images/youcai-wechat-share.png', // 分享图标
            success: function () {
              // 设置成功
              console.log('分享内容-ok')
            }
          }
          //自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
          wx.updateAppMessageShareData(share_data)
          //自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）
          wx.updateTimelineShareData(share_data)

          //获取“分享给朋友”按钮点击状态及自定义分享内容接口（即将废弃）
          wx.onMenuShareTimeline(share_data)
          //获取“分享到朋友圈”按钮点击状态及自定义分享内容接口（即将废弃）
          wx.onMenuShareAppMessage(share_data)
          //获取“分享到QQ”按钮点击状态及自定义分享内容接口（即将废弃）
          wx.onMenuShareQQ(share_data)
          wx.onMenuShareWeibo(share_data)
          wx.onMenuShareQZone(share_data)

        });
        wx.error(function (res) {
          console.log('微信分享Ready-fail')
          console.error(res)
        });
      }
    })

  },
  methods: {
    init() {
      this.load.where = true
      this.$axios.post(this.$apiconfg.index, this.where).then(({ data }) => {
        if (data.code === 1) {
          this.city = data.data.city
          this.year = data.data.year
          this.category = data.data.category
          this.list = data.data.list
          this.where = data.data.where
        }
        this.load.where = false
      }).catch(() => {
        this.load.where = false
      })
    },
    init_config() {
      this.load.where = true
      this.$axios.post(this.$apiconfg.index_config).then(({ data }) => {
        this.city = data.data.city
        this.year = data.data.year
        this.load.where = false
      }).catch(() => {
        this.load.where = false
      })
    },
    change_category(item) {
      this.where.category = item.id
      this.init()
    },
    change_city(item) {
      this.where.city = item.name
      this.where.category = null
      this.init()
    },
    change_year(item) {
      this.where.year = item.year
      this.where.category = null
      this.init()
    }
  }
}
</script>
