<template>
  <div>
    <div class="top">

      <div class="top-bar">
        测评筛选
        <span class="goto-more">
          <a href="//rank.dichan.com/" target="_blank">全国测评报告 <i class='el-icon-arrow-right'></i></a>
        </span>
      </div>

      <div v-loading="load.where" class="where" element-loading-background="rgba(0,0,0,.2)">
        <el-row>
          <el-col :span="1" class="left">区域：</el-col>
          <el-col :span="23" class="right dark">
            <span v-for="item in city" :key="item.name" :class="where.city===item.name?'cur':''"
                  @click="change_city(item)">{{ item.name }}</span>
            <span v-if="!city">-</span>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="1" class="left">年份：</el-col>
          <el-col :span="23" class="right dark">
            <span v-for="item in year" :key="item.name" :class="where.year===item.year?'cur':''"
                  @click="change_year(item)">{{ item.label }}</span>
            <span v-if="!year">-</span>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="1" class="left">品类：</el-col>
          <el-col :span="23" class="right ">
            <span v-for="item in category" :key="item.name" :class="where.category===item.id?'cur':''"
                  @click="change_category(item)">{{
                item.title
              }}</span>
            <span v-if="!category || category.length < 1">暂无匹配行业</span>
          </el-col>
        </el-row>
      </div>

    </div>

    <div class="section">
      <div class="title">
        {{ where.city }} - {{ where.year }}年度首选品牌测评报告
        <span class="my-coll">
          <a v-if="this.$store.state.auth && this.$store.state.auth.hall.industry ===2"
             href="http://youcaiyun.com/Pages/Provider/Web/AboutBidList" target="_blank">行业动态 <i
              class='el-icon-arrow-right'></i></a>
          <router-link v-else to="/Reserve">我的储备库  <i class='el-icon-arrow-right'></i></router-link>
        </span>
      </div>
      <div v-if="list" class="list">
        <div class="item">
          <div class="center" v-if="list.data.length >0">
            <span class="eo-title"> {{ list.title }} </span>
          </div>
          <dl class="data-list">
            <dd v-for="item in list.data" :key="item.id" class="clearfix">
              <div>
                TOP {{ item.show_sort }}
              </div>
              <div>
                <a :href="item.company.hall?item.company.hall.url:'#'" target="_blank">
                  <el-image :src="item.company.logo" class="logo" fit="contain" lazy>
                    <div slot="error" style="display: flex;align-items: center;height: 100%;">
                      <div style="font-size: 12px;color: #606266;width: 100%;text-align: center">暂无企业logo</div>
                    </div>
                  </el-image>
                </a>
              </div>
              <div>
                <div class="company-title">
                  <a :href="item.company.hall?item.company.hall.url:'#'" target="_blank">
                    {{ item.company.CompanyName }} <span class="more-goto">前往企业展厅<i
                      class="el-icon-arrow-right"></i></span>
                  </a>
                </div>
                <div class="tag-box">
                  <span class="tag-cp">{{ item.rank_brand }}</span>
                  <span class="tag-cp">{{
                      list.interpretive_prefix
                    }} <span>{{ item.unit_val }}{{ list.interpretive_unit }}</span></span>
                  <span v-for="tags in item.company.tags" :key="tags.title" class="tag-cp">{{
                      tags.title
                    }} <span>{{ tags.value }}</span></span>
                </div>
              </div>
              <div class="btn-box">
                <collection :company_id="item.company.CompanyId"></collection>
                <connect :company="item.company"></connect>
              </div>
            </dd>

          </dl>
        </div>

        <div v-for="child in list.child" :key="child.id" class="item">
          <div v-if="child.data.length > 0" class="center">
            <span class="eo-title">子分类 - {{ child.title }} </span>
          </div>
          <dl class="data-list">
            <dd v-for="item in child.data" :key="item.id" class="clearfix">
              <div>
                TOP {{ item.show_sort }}
              </div>
              <div>
                <a :href="item.company.hall?item.company.hall.url:'#'" target="_blank">
                  <el-image :src="item.company.logo" class="logo" fit="contain" lazy>
                    <div slot="error" style="display: flex;align-items: center;height: 100%;">
                      <div style="font-size: 12px;color: #606266;width: 100%;text-align: center">暂无企业LOGO</div>
                    </div>
                  </el-image>
                </a>
              </div>
              <div>
                <div class="company-title">
                  <a :href="item.company.hall?item.company.hall.url:'#'" target="_blank">{{
                      item.company.CompanyName
                    }}
                    <span class="more-goto">前往企业展厅<i class="el-icon-arrow-right"></i></span>
                  </a>
                </div>
                <div class="tag-box">
                  <span class="tag-cp">{{ item.rank_brand }}</span>
                  <span class="tag-cp">{{
                      child.interpretive_prefix
                    }} <span>{{ item.unit_val }}{{ child.interpretive_unit }}</span></span>
                  <span v-for="tags in item.company.tags" :key="tags.title" class="tag-cp">{{
                      tags.title
                    }} <span>{{ tags.value }}</span></span>
                </div>
              </div>
              <div class="btn-box">
                <collection :company_id="item.company.CompanyId"></collection>
                <connect :company="item.company"></connect>
              </div>
            </dd>

          </dl>
        </div>


      </div>

      <el-empty v-else description="暂无匹配数据，敬请期待!" style="min-height: 500px"></el-empty>

    </div>

  </div>
</template>

<style lang="less">
@import '@/assets/css/public.less';

body {
  background: @color-index-bg;
}
</style>
<style lang="less" scoped>
@import '@/assets/css/public.less';

.top {
  color: #ffffff;
  box-sizing: border-box;
  margin: 10px auto;
  width: 1200px;
  padding: 10px;
  background: @color-index-bg-lv1;
  border-radius: 3px;
}

.top-bar {
  box-sizing: border-box;
  font-size: 13px;
  border-bottom: 1px solid @color-index-font-light;
  padding-bottom: 10px;
}

.goto-more, .goto-more a {
  font-size: 12px;
  float: right;
  color: #FFFFFF;
  text-decoration: none;
}

.goto-more, .goto-more a:hover {
  color: @color-index-font-light;
}

.where {
  font-size: 12px;
  padding-top: 10px;
}

.where > div {
}

.where .right {
  padding-left: 3px;
}

.where .left {
  margin-bottom: 10px;
  letter-spacing: 4px;
  font-size: 12px;
  color: rgba(255, 255, 255, .5);
}

.where .right > span {
  display: inline-block;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  letter-spacing: 1px;
  cursor: pointer;
}

.where .right > span:hover {
  color: @color-index-font-light-lv1;
  transition: all ease .3s;
}

.where .cur {
  color: @color-index-font-light;
  font-weight: bold;
}

.where .right.dark > span:hover {
  color: @color-index-font-light;
  transition: all ease .3s;
}

.where .dark .cur {
  color: @color-index-font-light-lv1;
  font-weight: bold;
}

.section {
  margin: 20px auto;
  box-sizing: border-box;
  padding: 10px;
  width: 1200px;
  background: @color-index-bg-lv1;
  border: 1px solid @color-index-font-light-lv1;
  color: #ffffff;
}

.section .title {
  font-size: 18px;
  padding-bottom: 10px;
  border-bottom: 1px solid @color-index-font-light;
  letter-spacing: 1px;
  color: @color-index-font-light;
}


.section .list .eo-title {
  font-size: 20px;
  color: @color-index-font-light;
  font-weight: bold;
  letter-spacing: 1.5px;
}

.eo-title::after {
  content: '●';
  text-align: left;
  display: inline-block;
  width: 150px;
  height: 4px;
  background: linear-gradient(90deg, #F8C8A3 0%, #2E2A29 100%);
  opacity: 1;
  border-radius: 3px;
  line-height: 0;
  background-position: 8px;
}

.eo-title::before {
  content: '●';
  text-align: right;
  display: inline-block;
  width: 150px;
  height: 4px;
  background: linear-gradient(270deg, #F8C8A3 0%, #2E2A29 100%);
  opacity: 1;
  border-radius: 3px;
  line-height: 0;
  background-position: -8px;
}

.section .list .data-list {
  padding: 0;
  margin: 20px 0 0 0;
}

.section .list .data-list dd {
  margin: 0 0 20px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #514C4B;
  padding-bottom: 20px;
}

.section .list .data-list dd:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.section .list .data-list dd > div {
  float: left;
  margin-right: 10px;
}

.section .list .data-list dd > div:nth-child(1) {
  width: 80px;
  font-family: fantasy;
  letter-spacing: 1.5px;
  font-weight: 400;
  text-indent: 10px;
  color: @color-index-font-light;
}

.section .list .data-list dd > div:nth-child(2) {
  width: 160px;
}

.section .list .data-list dd > div:nth-child(3) {
  width: calc(100% - 10px * 3 - 80px - 160px - 240px);
}

.section .list .data-list dd > div:nth-child(4) {
  width: 240px;
  text-align: right;
}

.data-list .logo {
  width: 133px;
  height: 70px;
  background: #FFFFFF;
  opacity: 1;
  border-radius: 4px;
  object-fit: contain;
  padding: 5px 10px;
}

.data-list .company-title a {
  font-size: 16px;
  letter-spacing: 2px;
  color: @color-index-font-light;
  text-decoration: none;
}

.data-list .tag-box {
  margin-top: 15px;
}

.data-list .tag-box > span {
  margin-right: 10px;
}

.is-background-p /deep/ p {
  font-size: 12px;
  text-indent: 2rem;
  letter-spacing: 1.2px;
  line-height: 1.25rem;
  color: #dfc5b0;
}

.data-list .tag-box > span:last-child {
  margin-right: 0;
}

.data-list .tag-box .tag-cp {
  font-size: 12px;
  font-weight: 400;
  color: @color-index-font-light-dark;
  background: linear-gradient(280deg, #FEEACA 0%, #FBD3A8 100%);
  border-radius: 2px;
  overflow: hidden;
  display: inline-block;
  min-width: 4rem;
  vertical-align: middle;
  text-align: center;
  height: 22px;
  padding: 0 5px;
  line-height: 22px;
}

.data-list .tag-box .tag-cp > span {
  background: @color-index-font-purple;
  color: @color-index-font-purple-lv1;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  -webkit-clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%);
  text-align: center;
  display: inline-block;
  height: 22px;
  padding: 0 .5rem;
  margin-right: -5px;
  line-height: 22px;
  min-width: 3rem;
}

.data-list .btn-box > span {
  margin-right: 15px;
}

.data-list .btn-box > span:last-child {
  margin-right: 0;
}

.data-list .company-title .more-goto {
  opacity: 0;
  font-size: 12px;
  transition: all ease .3s;
}

.data-list > dd:hover .company-title .more-goto {
  opacity: 1;
  color: @color-danger;
}

.section .list .item {
  margin-top: 80px;
}

.is-background {
  width: 1200px;
  margin: 15px auto;
  color: #FFFFFF;
  padding: 20px 10px;
  background: @color-index-bg-lv1;
  box-sizing: border-box;
  border-radius: 2px;
}

.is-background p {
  font-size: 12px;
  text-indent: 2rem;
  letter-spacing: 1.2px;
  line-height: 1.25rem;
  color: rgba(223, 197, 176, 1);
}

.organization {
  margin: 15px auto;
  color: #FFFFFF;
  padding: 20px 10px;
  background: @color-index-bg-lv1;
  box-sizing: border-box;
  border-radius: 2px;
}

.bg-title {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1.5px;
  color: @color-index-font-light;
}

.bg-title::after {
  content: " ";
  width: 120px;
  display: inline-block;
  height: 12px;
  background: url("~@/assets/images/title-right.png") no-repeat;
  background-size: contain;
}

.bg-title::before {
  content: " ";
  width: 120px;
  display: inline-block;
  height: 12px;
  background: url("~@/assets/images/title-left.png") no-repeat;
  background-size: contain;
  background-position: right;

}

.logo-unit {
  padding: 10px;
  background: #FFFFFF;
  object-fit: contain;
  height: 80px;
  width: 158px;
}

.item-unit {
  margin: 60px auto;
  display: flex;
  align-items: center;
}

.item-unit p {
  font-size: 12px;
  letter-spacing: 1.2px;
  line-height: 1.25rem;
  color: rgba(223, 197, 176, 1);
}

.un-it {
  position: absolute;
  border-radius: 8px 0 0 0;
  height: 25px;
  box-sizing: border-box;
  padding: 0 15px;
  line-height: 25px;
  background: @color-index-font-light;
  font-size: 12px;
  left: 0;
  top: -25px;
  color: @color-index-font-light-dark;
  letter-spacing: 1px;
}

.tips-footer {
  font-size: 12px;
  color: #FFFFFF;
  margin: 10px auto;
}

.my-coll {
  font-size: 12px;
  float: right;
  height: 30px;
  line-height: 25px;
}

.my-coll a {
  text-decoration: none;
  color: #FFFFFF;
  letter-spacing: 0;
}

.my-coll a:hover {
  color: @color-index-font-light;
}
</style>
<script>
import Collection from "../components/Collection";
import Connect from "../components/Connect";

export default {
  name: "Index",
  components: {Connect, Collection},
  data() {
    return {
      city: null,
      year: null,
      category: null,
      list: null,
      load: {
        where: false
      },
      where: {
        city: null,
        year: null,
        category: null
      },
      copywriting: null
    }
  },
  metaInfo: {
    title: 'TOP500房建供应链首选供应商服务商品牌测评研究报告-首页',
    htmlAttrs: {
      lang: 'zh-CN',
      keyword: '优采云，大数据，云服务，区域测评，报告',
      amp: true
    }
  },
  mounted() {

    this.init()

    this.$axios.post(this.$apiconfg.index_config).then(({data}) => {
      if (data.code === 1) {
        this.copywriting = data.data
      }
    })

    //微信分享
    var wx = require('weixin-js-sdk');
    this.$axios.post(this.$apiconfg.wechat, {
      url: location.href
    }).then(({data}) => {
      if (data.code === 1) {
        const config = data.data
        wx.config(config)
        wx.ready(function () {
          console.log('微信分享Ready-ok')

          const share_data = {
            title: 'TOP500房建供应链首选供应商服务商品牌测评研究报告在线查询-优采云大数据服务平台', // 分享标题
            desc: '为了呈现区域选材特点，促进工程市场深耕细作，并对供应商的区域表现进行细分评价，形成首选品牌重点行业的区域测评分析，进而促进区域协调发展。', // 分享描述
            link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://api-report.dichan.com/static/images/youcai-wechat-share.png', // 分享图标
            success: function () {
              // 设置成功
              console.log('自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容-ok')
            }
          }
          //自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
          wx.updateAppMessageShareData(share_data)
          //自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）
          wx.updateTimelineShareData(share_data)

          //获取“分享给朋友”按钮点击状态及自定义分享内容接口（即将废弃）
          wx.onMenuShareTimeline(share_data)
          //获取“分享到朋友圈”按钮点击状态及自定义分享内容接口（即将废弃）
          wx.onMenuShareAppMessage(share_data)
          //获取“分享到QQ”按钮点击状态及自定义分享内容接口（即将废弃）
          wx.onMenuShareQQ(share_data)
          wx.onMenuShareWeibo(share_data)
          wx.onMenuShareQZone(share_data)

        });
        wx.error(function (res) {
          console.log('微信分享Ready-fail')
          console.error(res)
        });
      }
    })

  },
  methods: {
    init() {
      this.load.where = true
      this.$axios.post(this.$apiconfg.index, this.where).then(({data}) => {
        if (data.code === 1) {
          this.city = data.data.city
          this.year = data.data.year
          this.category = data.data.category
          this.list = data.data.list
          this.where = data.data.where
        }
        this.load.where = false
      }).catch(() => {
        this.load.where = false
      })
    },
    init_config() {
      this.load.where = true
      this.$axios.post(this.$apiconfg.index_config).then(({data}) => {
        this.city = data.data.city
        this.year = data.data.year
        this.load.where = false
      }).catch(() => {
        this.load.where = false
      })
    },
    change_category(item) {
      this.where.category = item.id
      this.init()
    },
    change_city(item) {
      this.where.city = item.name
      this.where.category = null
      this.init()
    },
    change_year(item) {
      this.where.year = item.year
      this.where.category = null
      this.init()
    }
  }
}
</script>
