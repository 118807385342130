<template>
  <span>
    <el-button :class="is_mark?'is_mark':''" :loading="load" class="btn-is_mark_purple"
               @click="open"
               @mouseover.native="hovering('over')"
               @mouseout.native="hovering('out')"
    >{{ text }}
    </el-button>
      <el-dialog
          :visible.sync="show"
          class="mini-pd-dialog"
          width="360px"
      >
        <div class="center ">
                <el-image :src="company.logo" class="logo" lazy>
                  <div slot="error" style="display: flex;align-items: center;height: 100%;">
                    <div style="font-size: 12px;color: #606266;width: 100%;text-align: center">暂无企业LOGO</div>
                  </div>
                </el-image>
          <div class="title">{{ company.CompanyName }}</div>
        </div>
        <div class="form-tips">
          <i class="el-icon-bell"></i> 请确认您的信息，方便企业与您联系
        </div>
       <el-form ref="form" :model="form" :rules="rules" label-position="right">
        <el-form-item>
          <el-input v-model="form.company_name" placeholder="请输入您的企业名称"
                    prefix-icon="el-icon-office-building"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="form.real_name" placeholder="请输入您的真实姓名" prefix-icon="el-icon-user"></el-input>
        </el-form-item>
        <el-form-item prop="phone">
          <el-input v-model="form.phone" maxlength="11" placeholder="请输入您的手机号"
                    prefix-icon="el-icon-mobile-phone"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="show = false">取 消</el-button>
        <el-button :loading="load" type="primary" @click="save('form')">确 定</el-button>
      </span>
      </el-dialog>
    </span>
</template>


<style lang="less" scoped>
@import '@/assets/css/public.less';

.btn-is_mark_purple {
  background: #B3002C;
  text-align: center;
  display: inline-block;
  font-size: 12px;
  border-radius: 19px;
  color: #ffffff;
  border: none;
  min-width: 100px;
  height: 35px;
  cursor: pointer;
}

.btn-is_mark_purple.is_mark {
  opacity: .8;
  cursor: no-drop;
}

.btn-is_mark_purple:hover, .btn-is_mark_purple:active, .btn-is_mark_purple:focus {
  color: #FFFFFF;
  background: #B3002C;
  text-decoration: underline;
}

.popup_left {
  text-align: left;
  color: @text;
}

.logo {
  width: 120px;
  height: 60px;
  background: #FFFFFF;
  opacity: 1;
  border-radius: 4px;
  object-fit: contain;
  padding: 5px 10px;
  box-sizing: border-box;
  border: 1px solid @text-lv3;
}

.mini-pd-dialog /deep/ .el-dialog__body {
  padding: 10px;
}

.mini-pd-dialog /deep/ .el-dialog__header {
  text-align: left;
  padding: 10px 10px 20px 10px;
  font-size: 12px;
}

.title {
  font-size: 12px;
  margin: 10px auto;
  letter-spacing: 1px;
  font-weight: bold;
}

.form-tips {
  font-size: 12px;
  color: @color-red;
  margin: 20px auto 10px;
  text-align: left;
}
</style>
<script>
export default {
  name: "Connect",
  props: [
    'company'
  ],
  data() {
    var validatePhone = (rule, value, callback) => {
      if (!/^1\d{10}$/.test(value)) {
        callback(new Error('手机号格式不正确'))
      } else {
        callback()
      }
    };
    return {
      text: '联系Ta',
      load: false,
      show: false,
      is_mark: false,
      form: {
        company_name: "",
        real_name: "",
        phone: "",
        company_id: this.company.CompanyId,
      },
      rules: {
        phone: [
          {required: true, message: '请输入手机号，示例：185xxxx1234', trigger: 'change'},
          {validator: validatePhone, trigger: 'change'}
        ],
      }
    }
  },
  watch: {
    load: function (val) {
      if (val) {
        this.text = ''
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (this.$store.state.isLogin) {
        this.load = true
        this.$axios.post(this.$apiconfg.connect_check, {
          company_id: this.company.CompanyId
        }).then(({data}) => {
          this.load = false
          if (data.code === 1) {
            if (data.is_mark) {
              this.text = '已联系Ta'
            } else {
              this.text = '联系Ta'
            }
            this.is_mark = data.is_mark
          }
        }).catch(() => {
          this.load = false
        })
      }
    },
    hovering(type) {
      switch (type) {
        case "over":
          if (this.is_mark) {
            this.text = '已联系Ta'
          } else {
            this.text = '联系Ta'
          }
          break
        case 'out':
          if (this.is_mark) {
            this.text = '已联系Ta'
          } else {
            this.text = '联系Ta'
          }
          break
      }

    },
    open() {
      if (!this.$store.state.isLogin) {
        this.$message.warning("请登录后再操作！")
        return
      }

      if (this.is_mark) {
        this.$message.warning("您已提交申请，请耐心等待工作人员与您联系！")
        return
      }

      this.show = true
      this.form.company_name = this.$store.state.auth.company.CompanyName
      this.form.real_name = this.$store.state.auth.RealName
      this.form.phone = this.$store.state.auth.call_phone

    },
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.load = true
          this.$axios.post(this.$apiconfg.connect_add, this.form).then(({data}) => {
            this.load = false
            if (data.code === 1) {
              this.show = false
              this.init()
            }
          })
        } else {
          this.$message.warning('请完善表单信息');
          return false;
        }
      });
    }
  }
}
</script>