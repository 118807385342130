import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Login from "../views/Login";
import Header from "../views/layout/Header";
import Index from "../views/Index";
import Reserve from "../views/Reserve";
import Report from "../views/Report";
import r2024 from "../views/R2024";
import newsReport from "../views/NewsReport";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      isLogin: false,
    },
  },
  {
    path: "/",
    name: "/",
    components: {
      default: Index,
      header: Header,
    },
    meta: {
      isLogin: false,
    },
  },
  {
    path: "/r2023",
    name: "r2023",
    components: {
      default: newsReport,
      // header: Header
    },
    meta: {
      isLogin: false,
      autoRedirect: false,
    },
  },
  {
    path: "/r2024",
    name: "r2024",
    components: {
      default: r2024,
      // header: Header
    },
    meta: {
      isLogin: false,
      autoRedirect: false,
    },
  },
  {
    path: "/newsReport",
    name: "newsReport",
    components: {
      default: newsReport,
      // header: Header
    },
    meta: {
      isLogin: false,
      autoRedirect: false,
    },
  },
  {
    path: "/reserve",
    name: "Reserve",
    components: {
      default: Reserve,
      header: Header,
    },
    meta: {
      isLogin: true,
    },
  },
  {
    path: "/report",
    name: "Report",
    component: Report,
    meta: {
      isLogin: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

/*路由鉴权*/
router.beforeEach((to, from, next) => {
  if (to.name == null) {
    window.location.href = "/";
  }
  if (to.meta.isLogin && !store.state.isLogin) {
    console.warn("此页面需要登录，请先登录~");
    next({
      name: "Login",
      params: {
        uri: to.name === "Login" ? "/" : to.name,
      },
    });
  }

  if (process.env.NODE_ENV === "development") {
    //开发环境不自动判断设备
    next();
  } else {
    console.log(to.meta);
    //根据浏览环境自动重定向
    if (to.meta.autoRedirect !== false) {
      const domain = "https://m-report.dichan.com";
      if (
        navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
      ) {
        //当前环境是手机端：执行 PC->H5
        location.href = domain + to.path;
        console.log("当前环境是手机端： 执行 PC->H5");
        return;
      } else {
        //当前环境是PC端： 执行 H5->PC
        console.log("当前环境是PC端： 不执行 H5->PC");
      }
    } else {
      console.log("路由已设置 不自动重定向");
    }
    next();
  }
});

export default router;
