<template>
  <div id="app">
    <!--登录页 不需要模板-->
    <router-view v-if="this.$route.name==='Login'"></router-view>
    <div v-else>
      <router-view name="header" ref="header"></router-view>
      <transition name='el-fade-in-linear' mode='out-in'>
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>