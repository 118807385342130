<template>
  <el-button :class="is_coll?'is_coll':''" :loading="load" class="btn-is_coll"
             @click="save"
             @mouseover.native="hovering('over')"
             @mouseout.native="hovering('out')"
  >{{ text }}
  </el-button>
</template>
<style lang="less" scoped>
@import '@/assets/css/public.less';

.btn-is_coll {
  background:transparent;
  border-radius: 19px;
  display: inline-block;
  text-align: center;
  height: 35px;
  min-width: 100px;
  font-size: 12px;
  color: #B3002C;
  margin-right: 10px;
  border: 1px solid #B3002C;
}

.btn-is_coll:hover {
  background:#B3002C;
  color: #ffffff;
  border: 1px solid #B3002C;
  text-decoration: underline;
}

.btn-is_coll:active /deep/ span, .btn-is_coll:focus /deep/ span {
  color: @color-index-font-light-dark;
}

.btn-is_coll.is_coll {
  opacity: .8;
}
</style>
<script>
export default {
  name: "Collection",
  props: [
    'company_id'
  ],
  data() {
    return {
      text: '加入储备库',
      load: false,
      is_coll: false
    }
  },
  watch: {
    load: function (val) {
      if (val) {
        this.text = ''
      }
    }
  },
  mounted() {

    this.init()
  },
  methods: {
    init() {
      if (this.$store.state.isLogin) {
        this.load = true
        this.$axios.post(this.$apiconfg.collection_check, {
          company_id: this.company_id
        }).then(({data}) => {
          this.load = false
          if (data.code === 1) {
            if (data.is_coll) {
              this.text = '已入库'
            } else {
              this.text = '加入储备库'
            }
            this.is_coll = data.is_coll
          }
        }).catch(() => {
          this.load = false
        })
      }
    },
    hovering(type) {
      switch (type) {
        case "over":
          if (this.is_coll) {
            this.text = '取消入库'
          } else {
            this.text = '点击入库'
          }
          break
        case 'out':
          if (this.is_coll) {
            this.text = '已入库'
          } else {
            this.text = '加入储备库'
          }
          break
      }

    },
    save() {
      if (!this.$store.state.isLogin) {
        this.$message.warning("请登录后再操作！")
        return
      }

      this.load = true
      this.$axios.post(this.$apiconfg.collection_update, {
        company_id: this.company_id,
        status: !this.is_coll
      }).then(() => {
        this.init()
      })
    }
  }
}
</script>

