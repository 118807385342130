<template>
  <div>
    <div class="top">
      <div class="router-bar">
        <router-link to="/"><i class="el-icon-arrow-left">返回</i></router-link>
        <span class="router-name">我的资源储备库</span>
      </div>
      <div>
        <img src="@/assets/images/reserve_bg.png">
      </div>
    </div>

    <div class="section">
      <div class="list">
        <el-input v-model="keyword" class="search-input-c" clearable placeholder="请输入企业名称，回车搜索"
                  prefix-icon="el-icon-search"
                  @keydown.enter.native="init"></el-input>
        <div v-loading="load" class="item" element-loading-background="rgba(255, 255, 255, 0.1)"
             element-loading-text="拼命加载中">
          <el-empty v-show="list.length < 1"></el-empty>
          <dl class="data-list">
            <dd v-for="item in list" :key="item.company_id" class="clearfix">
              <div>
                <a :href="item.hall?item.hall.url:'#'" target="_blank">
                  <el-image :src="item.logo" class="logo" fit="contain" lazy>
                    <div slot="error" style="display: flex;align-items: center;height: 100%;">
                      <div style="font-size: 12px;color: #606266;width: 100%;text-align: center">暂无企业LOGO</div>
                    </div>
                  </el-image>
                </a>
              </div>
              <div class="mg-10-left">
                <div class="company-title">
                  <a :href="item.hall?item.hall.url:'#'" target="_blank">
                    {{ item.CompanyName }}  <span class="more-goto">前往企业展厅<i class="el-icon-arrow-right"></i></span>
                  </a>
                </div>
                <div class="tag-box clearfix">
                  <span class="tag-cp cp-sort">{{ item.ShortName }}</span>
                  <span class="tag-cp">统一信用代码: {{ item.CreditCode ? item.CreditCode:'-' }} </span>
                </div>
              </div>
              <div class="btn-box">
                <collection :company_id="item.company_id"></collection>
                <connect :company="item"></connect>
              </div>
            </dd>
          </dl>
        </div>
      </div>
      <div class="center">
        <el-pagination
            :page-size="10"
            :total="total"
            background
            class="red-pagination"
            hide-on-single-page
            layout="prev, pager, next,total"
            @current-change="change_page">
        </el-pagination>
      </div>
    </div>

    <div class="center mg-20-top">
      <img src="@/assets/images/logo_guide.png">
      <div class="tips-footer">
        沪ICP备16028066号-2
      </div>
      <div class="tips-footer">
        需要帮助？联系客服 400-6188989
      </div>
    </div>

  </div>
</template>


<style lang="less">
@import '@/assets/css/public.less';

body {
  background: @color-index-bg;
}
</style>
<style lang="less" scoped>
@import '@/assets/css/public.less';

.top {
  color: #ffffff;
  box-sizing: border-box;
  margin: 10px auto;
  width: 1200px;
  font-size: 12px;
}

.top-bar {
  box-sizing: border-box;
  font-size: 13px;
  border-bottom: 1px solid @color-index-font-light;
  padding-bottom: 10px;
}

.goto-more, .goto-more a {
  float: right;
  color: @color-index-font-light;
  text-decoration: none;
}

.where {
  font-size: 12px;
  margin-top: 10px;
}

.where > div {
}

.where .right {
  padding-left: 3px;
}

.where .right > span {
  display: inline-block;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  letter-spacing: 1px;
  cursor: pointer;
}

.where .right > span:hover {
  color: @color-index-font-light-lv1;
  transition: all ease .3s;
}

.where .cur {
  color: @color-index-font-light;
  font-weight: bold;
}

.where .right.dark > span:hover {
  color: @color-index-font-light;
  transition: all ease .3s;
}

.where .dark .cur {
  color: @color-index-font-light-lv1;
  font-weight: bold;
}

.section {
  margin: 20px auto;
  box-sizing: border-box;
  padding: 10px;
  width: 1200px;
  background: @color-index-bg-lv1;
  color: #ffffff;
}

.section .title {
  color: #ffffff;
  font-size: 14px;
  padding-bottom: 10px;
  border-bottom: 1px solid @color-index-font-light;
  letter-spacing: 1px;
}

.section .list {
  margin-top: 10px;
  padding-bottom: 30px;
}

.section .list .eo-title {
  font-size: 20px;
  color: @color-index-font-light;
  font-weight: bold;
  letter-spacing: 1.5px;
}

.eo-title::after {
  content: '●';
  text-align: left;
  display: inline-block;
  width: 150px;
  height: 4px;
  background: linear-gradient(90deg, #F8C8A3 0%, #2E2A29 100%);
  opacity: 1;
  border-radius: 3px;
  line-height: 0;
  background-position: 8px;
}

.eo-title::before {
  content: '●';
  text-align: right;
  display: inline-block;
  width: 150px;
  height: 4px;
  background: linear-gradient(270deg, #F8C8A3 0%, #2E2A29 100%);
  opacity: 1;
  border-radius: 3px;
  line-height: 0;
  background-position: -8px;
}

.section .list .data-list {
  padding: 0;
  margin: 0;
}

.section .item {
  min-height: 300px;
}

.section .list .data-list dd {
  margin: 0 0 20px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #514C4B;
  padding-bottom: 20px;
}

.section .list .data-list dd:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.section .list .data-list dd > div {
  float: left;
  margin-right: 10px;
}


.section .list .data-list dd > div:nth-child(1) {
  width: 160px;
  box-sizing: border-box;
  padding-left: 10px;
}

.section .list .data-list dd > div:nth-child(2) {
  width: calc(100% - 10px * 2 - 160px - 240px);
}

.section .list .data-list dd > div:nth-child(3) {
  width: 240px;
  text-align: right;
}

.data-list .logo {
  width: 133px;
  height: 70px;
  background: #FFFFFF;
  opacity: 1;
  border-radius: 4px;
  object-fit: contain;
  padding: 5px 10px;
}

.data-list .company-title a {
  font-size: 16px;
  letter-spacing: 2px;
  color: @color-index-font-light;
  text-decoration: none;
}
.data-list .company-title .more-goto{
  opacity: 0;
  font-size: 12px;
  transition: all ease .3s;
}
.data-list >dd:hover .company-title .more-goto{
  opacity: 1;
  color: @color-danger;
}

.data-list .tag-box {
  margin-top: 15px;
}

.data-list .tag-box > span {
  margin-right: 10px;
}

.data-list .tag-box > span:last-child {
  margin-right: 0;
}

.data-list .tag-box .tag-cp {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: @color-index-font-light-dark;
  background: linear-gradient(280deg, #FEEACA 0%, #FBD3A8 100%);
  padding: 5px 8px;
  border-radius: 2px;
  overflow: hidden;
  min-width: 4rem;
  display: block;
  float: left;
  text-align: center;
}

.data-list .tag-box .tag-cp.cp-sort {
  background: @color-danger;
  color: @color-danger-lv1;
}

.data-list .btn-box > span {
  margin-right: 15px;
}

.data-list .btn-box > span:last-child {
  margin-right: 0;
}

.btn-is_coll {
  background: linear-gradient(118deg, #FFF0D3 0%, #E8B279 100%);
  border-radius: 19px;
  display: inline-block;
  text-align: center;
  box-sizing: border-box;
  min-width: 100px;
  padding: 10px 15px;
  font-size: 12px;
  color: @color-index-font-light-dark;
}


.btn-is_coll_purple {
  background: linear-gradient(122deg, #C7B8FF 0%, #C4B5FF 0%, #512DEF 100%);
  padding: 10px 15px;
  text-align: center;
  box-sizing: border-box;
  display: inline-block;
  font-size: 12px;
  min-width: 100px;
  border-radius: 19px;
  color: #EEE9FF;
}

.is-background {
  width: 1200px;
  margin: 15px auto;
  color: #FFFFFF;
  padding: 20px 10px;
  background: @color-index-bg-lv1;
  box-sizing: border-box;
  border-radius: 2px;
}

.is-background p {
  font-size: 12px;
  text-indent: 2rem;
  letter-spacing: 1.2px;
  line-height: 1.25rem;
  color: rgba(223, 197, 176, 1);
}

.organization {
  width: 1200px;
  margin: 15px auto;
  color: #FFFFFF;
  padding: 20px 10px;
  background: @color-index-bg-lv1;
  box-sizing: border-box;
  border-radius: 2px;
}

.bg-title {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1.5px;
  color: @color-index-font-light;
}

.bg-title::after {
  content: " ";
  width: 120px;
  display: inline-block;
  height: 12px;
  background: url("~@/assets/images/title-right.png") no-repeat;
  background-size: contain;
}

.bg-title::before {
  content: " ";
  width: 120px;
  display: inline-block;
  height: 12px;
  background: url("~@/assets/images/title-left.png") no-repeat;
  background-size: contain;
  background-position: right;

}

.logo-unit {
  padding: 10px;
  background: #FFFFFF;
  object-fit: contain;
  height: 80px;
  width: 158px;
}

.item-unit {
  margin: 60px auto;
  display: flex;
  align-items: center;
}

.item-unit p {
  font-size: 12px;
  letter-spacing: 1.2px;
  line-height: 1.25rem;
  color: rgba(223, 197, 176, 1);
}

.un-it {
  position: absolute;
  border-radius: 8px 0 0 0;
  height: 25px;
  box-sizing: border-box;
  padding: 0 15px;
  line-height: 25px;
  background: @color-index-font-light;
  font-size: 12px;
  left: 0;
  top: -25px;
  color: @color-index-font-light-dark;
  letter-spacing: 1px;
}

.tips-footer {
  font-size: 12px;
  color: #FFFFFF;
  margin: 10px auto;
}

.router-bar {
  padding: 10px 0;
  margin-bottom: 10px;
  border-bottom: 1px solid @color-index-font-light;
}

.router-bar a {
  color: #FFFFFF;
}

.router-bar a:hover {
  color: @color-index-font-light;
}

.router-bar .router-name {
  margin-left: 10px;
  color: @color-index-font-light;
  font-weight: bold;
}

.search-input-c /deep/ input {
  background: rgba(255, 255, 255, .1);
  border: none;
  color: @color-index-font-light;
}

.search-input-c {
  box-sizing: border-box;
  width: calc(100% - 10px * 2);
  margin: 0 auto 20px;
  display: block;
}

.red-pagination.el-pagination.is-background /deep/ .el-pager li:not(.disabled).active {
  background-color: #F56C6C;
}
</style>
<script>
import Collection from "../components/Collection";
import Connect from "../components/Connect";

export default {
  name: "Reserve",
  components: {Connect, Collection},
  data() {
    return {
      keyword: '',
      list: null,
      total: 0,
      load: false
    }
  },
  metaInfo: {
    title: 'TOP500房建供应链首选供应商服务商品牌测评研究报告-我的优质资源储备库',
    htmlAttrs: {
      lang: 'zh-CN',
      keyword:'优采云，大数据，云服务，区域测评，报告',
      amp: true
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init(page = 1) {
      this.load = true
      this.$axios.post(this.$apiconfg.collection, {
        keyword: this.keyword,
        page: page
      }).then(({data}) => {
        if (data.code === 1) {
          this.list = data.data.list
          this.total = data.data.total
        }
        this.load = false
      }).catch(() => {
        this.load = false
      })
    },
    change_page(page) {
      this.init(page)
    }
  }
}
</script>